import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { withTrans } from "../i18n/withTrans"
import Text from "../components/text"

const DatenschutzPage = ({t, ...props}) => (
  <Layout>
    <Seo title="Datenschutz"/>
    <main id="maincontent">
    <div style={{paddingTop: "30vh", paddingBottom: "20vh", paddingLeft:"15vw", paddingRight: "15vw", margin:"10px"}}>
        <h1>{t("datenschutz")}</h1>

        <h2 className="p11B">Datenschutzerklärung</h2>
        <p className="p11GM">
        <Text text={`
          Verantwortliche Stelle im Sinne der EU-Datenschutzgrundverordnung (DSGVO) ist
          Stadt Ulm
          89070 Ulm
          Telefon 0731 161-0
          Email: info@ulm.de
          Die Stadt Ulm ist eine Körperschaft des öffentlichen Rechts. Sie wird vertreten durch den Oberbürgermeister Gunter Czisch

          Verantwortlich für den Inhalt:
          Stadt Ulm
          Museum – HfG Archiv
          Am Hochsträß 8
          89081 Ulm
          Telefon 0731 161-4370
          Email: hfg-archiv@ulm.de

        `}/>
        </p>

        <h2 className="p11B">Nutzungsdaten</h2>
        <p className="p11GM">
        <Text text={`
          Bei jedem Zugriff auf unsere Webseite und bei jedem Abruf einer Datei werden automatisch über diesen Vorgang allgemeine Daten in einer Protokolldatei gespeichert. Die Speicherung dient ausschließlich systembezogenen und statistischen Zwecken (auf Grundlage von Art. 6 Abs. 1 Buchst. b) DSGVO), sowie in Ausnahmefällen zur Anzeige von Straftaten (auf Grundlage von Art. 6 Abs. 1 Buchst. e) DSGVO). Eine Weitergabe der Daten an Dritte oder eine sonstige Auswertung findet nicht statt, es sei denn, es besteht eine gesetzliche Verpflichtung dazu (Art. 6 Abs. 1 Buchst. e) DSGVO). Im Einzelnen wird über jeden Abruf folgender Datensatz gespeichert:

          • Name der abgerufenen Datei
          • Datum und Uhrzeit des Abrufs
          • übertragene Datenmenge
          • Meldung, ob der Abruf erfolgreich war
          • Beschreibung des Typs des verwendeten Webbrowsers
          • verwendetes Betriebssystem
          • die zuvor besuchte Seite
          • Provider
          • Ihre IP-Adresse

        `}/>
        </p>

        <h2 className="p11B">Ihre Betroffenenrechte</h2>
        <p className="p11GM">
        <Text text={`
          Unter den angegebenen Kontaktdaten können Sie hinsichtlich ihrer personenbezogenen Daten jederzeit die folgenden Rechte ausüben:

          • Auskunft über Ihre personenbezogenen Daten (Art. 15 DSGVO)
          • Berichtigung unrichtiger Daten (Art. 16 DSGVO),
          • Löschung (Art. 17 DSGVO),
          • Einschränkung der Datenverarbeitung (Art. 18 DSGVO),
          • Datenübertragbarkeit, wenn ein Vertrag oder Einwilligung vorliegt (Art. 20 DSGVO)
          • Widerspruch gegen die Datenverarbeitung (Art. 21 DSGVO).

          Sie können eine erteilte Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen.
          Sie können sich jederzeit mit einer Beschwerde an die für Sie zuständige Aufsichtsbehörde wenden (Art. 77 DSGVO).

        `}/>
        </p>

        <h2 className="p11B">Zwecke der Datenverarbeitung durch die verantwortliche Stelle</h2>
        <p className="p11GM">
        <Text text={`
          Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser Datenschutzerklärung genannten Zwecken. Eine Übermittlung Ihrer persönlichen
          Daten an Dritte zu anderen als den genannten Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:

          • Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,
          • die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich ist,
          • die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist,
          • die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein
          • überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben.

        `}/>
        </p>

        <h2 className="p11B">Analyse-Tools und Tools von Drittanbietern</h2>
        <p className="p11GM">
        <Text text={`
          Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden.
          Sie können dieser Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte Informationen zu diesen Tools und über Ihre Widerspruchsmöglichkeiten finden Sie in der folgenden Datenschutzerklärung.

        `}/>
        </p>

        <h2 className="p11B">Kontakt</h2>
        <p className="p11GM">
        <Text text={`
          Wenn Sie per E-Mail mit uns Kontakt aufnehmen, erteilen Sie uns zum Zwecke der Kontaktaufnahme Ihre Einwilligung, die mitgeteilte E-Mail-Adresse zur Zuordnung der Anfrage und der anschließenden Beantwortung zu nutzen. Nach Erledigung der Anfrage werden personenbezogene Daten gelöscht.
          Die o.g. Erhebung der personenbezogenen Daten ist notwendig zur korrekten Darstellung dieser Webseite und zur Bearbeitung ihres Anliegens.

        `}/>
        </p>

        <h2 className="p11B">Kontaktdaten des Datenschutzbeauftragten</h2>
        <p className="p11GM">
        <Text text={`
          Stadt Ulm
          ZSD/R Datenschutz
          Kornhausplatz 4
          89073 Ulm
          datenschutz@ulm.de

        `}/>
        </p>


        <h1 style={{marginTop:"100px"}}>DATENERFASSUNG AUF UNSERER WEBSITE</h1>

        <h2 className="p11B">Cookies</h2>
        <p className="p11GM">
        <Text text={`
          Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
          Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
          Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
          Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.

        `}/>
        </p>

        <h2 className="p11B">Analyse-Tools</h2>

        <h2 className="p11B">Google Analytics</h2>
        <p className="p11GM">
        <Text text={`
          Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
          Google Analytics verwendet so genannte „Cookies“. Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
          Die Speicherung von Google-Analytics-Cookies und die Nutzung dieses Analyse-Tools erfolgen auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren.

        `}/>
        </p>

        <h2 className="p11B">IP Anonymisierung</h2>
        <p className="p11GM">
        <Text text={`
          Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
        `}/>
        </p>

        <h2 className="p11B">Browser Plugin</h2>
        <p className="p11GM">
        <Text text={`
          Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: https://tools.google.com/dlpage/gaoptout?hl=de.

        `}/>
        </p>

        <h2 className="p11B">Widerspruch gegen Datenerfassung</h2>
        <p className="p11GM">
        <Text text={`
          Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website verhindert: [opt-out-cookie].
          Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google: https://support.google.com/analytics/answer/6004245?hl=de.

        `}/>
        </p>

        <h2 className="p11B">Auftragsverarbeitung</h2>
        <p className="p11GM">
        <Text text={`
          Wir haben mit Google einen Vertrag zur Auftragsverarbeitung abgeschlossen und setzen die strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.

        `}/>
        </p>

        <h2 className="p11B">Demografische Merkmale bei Google Analytics</h2>
        <p className="p11GM">
        <Text text={`
          Diese Website nutzt die Funktion „demografische Merkmale“ von Google Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet werden. Sie können diese Funktion jederzeit über die Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google Analytics wie im Punkt „Widerspruch gegen Datenerfassung“ dargestellt generell untersagen.

        `}/>
        </p>

        <h2 className="p11B">Speicherdauer</h2>
        <p className="p11GM">
        <Text text={`
          Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die mit Cookies, Nutzerkennungen (z.B. User ID) oder Werbe-IDs (z.B. DoubleClick-Cookies, Android-Werbe-ID) verknüpft sind, werden nach 14 Monaten anonymisiert bzw. gelöscht. Details hierzu ersehen Sie unter folgendem Link:https://support.google.com/analytics/answer/7667196?hl=de

        `}/>
        </p>

    </div>
    </main>
  </Layout>
)

export default withTrans(DatenschutzPage)
